<template>
  <router-view/>
</template>
<style>
#app {
  height: 100%;
}

body {
  background-color: #fafafa;
}
</style>
<script setup>
document.title = '在线交费'
</script>
