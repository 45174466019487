import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import weui from 'weui.js'
import Vant from 'vant'
import 'vant/lib/index.css';
import 'weui'
const app = createApp(App);
app.config.globalProperties.$weui = weui
app.provide('weui',weui)
app.use(Vant)
app.use(router).mount('#app')
