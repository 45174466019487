import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/pay',
        component: () => import('@/views/Pay.vue')
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
